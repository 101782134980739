@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,300;0,400;0,700;1,400&display=swap');

$font-color: #707070;
$primary: $font-color;
$border-radius: 0;
$secondary: #1DACAC;
// $info: #1DACAC;
$headings-font-weight: 700;

$card-border-width: 0;
$card-spacer-x: .5rem;
$card-spacer-y: 0rem;

$font-family-sans-serif: Overpass, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-color: $font-color;
$icon-care-color: #1DACAC;
$icon-care-card-color: #ffffff;
$icon-favorite-active: #ff0070;

$filter-background: #E3E3E3;

@import "../node_modules/bootstrap-scss";

h1,
h2,
h3 {
  font-weight: normal;
}

// .header {
//   margin-top: 20px;
// }

.navbar-light {
  .navbar-brand {
    color: $font-color;
  }
}
.nav-link.btn-primary {
  color: var(--bs-btn-color);
}

.list-pipe+.list-pipe::before {
  display: inline-block;
  padding-right: 0.5rem;
  content: "|";
}

.plant-grid {
  position: relative;
  margin-bottom: 60px;
}

.plant-count {
  position: absolute;
  top: -20px;
  right: 15px;
}

@media screen and (max-width: 370px) {
  .container-card {
    width: 100%;
  }

  .container-card .card {
    max-width: 343px;
    margin: 0 auto;
  }
}

.card a {
  color: $font-color;
  text-decoration: none;
}

.card a:hover {
  text-decoration: none;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, .5);
}

.card-head {
  height: 250px;
  overflow: hidden;
  position: relative;
}

// .card-head img {
//   position: absolute;
//   top: -9999px;
//   bottom: -9999px;
//   left: -9999px;
//   right: -9999px;
//   margin: auto;
// }

// .card-head img.img-horiz {
//   height: 100%;
//   width: auto;
// }

// .card-head .img-vert {}

.card-head figure,
.card-head img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-title {
  margin-bottom: 0;
  line-height: 1;
}

.card-body p {
  margin-bottom: 0px;
  line-height: 1.2;
  margin-top: 3px;
}

.figure {
  position: relative;
}

.figure-caption {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  color: white;
  padding: 2px 8px;
  font-weight: 300;
  font-size: .75rem;
}

.favorite {
  width: 26px;
  line-height: 1;
  padding: 0;
  stroke: $font-color;
}

.plant-page .favorite {
  position: absolute;
  right: 0px;
  top: -3px;
}

.card .favorite {
  position: absolute;
  top: 6px;
  left: 4px;
  width: 22px;
  line-height: 1;
  background: transparent;
  border: 0;
  padding: 0;
}


svg {
  position: relative;
}

svg:after {
  // content: attr(data-tooltip);
  content: "hi there";
  // position: absolute;
  // left: 50%;
  // bottom: 100%;
  /* put it on the top */
  background-color: yellow;
  // width: max-content;
  // opacity: 0;
  // -webkit-transition: opacity 0.75s ease-in-out;
}

svg:hover:after {
  opacity: 1;
}

svg:after {
  left: 5px !important;
}

.favorite svg {
  fill: transparent;
  stroke-width: 13px;
}

.card .favorite svg {
  stroke: $icon-care-card-color;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
}

.favorite:focus {
  outline: 0px;
  box-shadow: none;
}

.favorite:hover:not(.fav-active) svg {
  fill: $font-color;
}

.card .favorite:hover:not(.fav-active) svg {
  fill: $icon-care-card-color;
}

.card.favorite:focus svg {
  filter: drop-shadow(0px 0px 2px $blue);
}

.fav-active svg {
  fill: $icon-favorite-active;
  stroke: $icon-favorite-active;
}

.card .fav-active svg {
  fill: $icon-favorite-active;
  stroke: $icon-favorite-active;
}

.careIcons {
  line-height: 1;
  @include clearfix
}

.colorIcons {
  line-height: 1;
  @include clearfix
}

.card .careIcons {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  height: 25px;
  right: 0;
  left: 0;
}

.careIcons svg {
  height: 35px;
  margin: 3px 2px 2px;
  stroke-width: 9px;
  fill: $icon-care-color;
  stroke: $icon-care-color;
}

.colorIcons svg {
  height: 25px;
  margin: 3px 2px 2px;
}

.card .careIcons svg {
  height: 19px;
  margin: 3px 2px 2px;
  stroke-width: 9px;
  fill: $icon-care-card-color;
  stroke: $icon-care-card-color;
}

svg.icon-sun .sun-outer {}

svg.icon-sun .sun-inner {}

svg.icon-part-shade .sun-outer {
  fill: transparent;
}

svg.icon-part-shade .sun-inner {
  stroke: transparent;
}

svg.icon-shade .sun-outer {
  fill: transparent;
}

svg.icon-shade .sun-inner {
  fill: transparent;
  stroke: transparent;
}

svg.icon-deep-shade .sun-outer {
  fill: transparent;
  stroke: transparent;
}

svg.icon-deep-shade .sun-inner {
  fill: transparent;
}

svg.icon-moistureNeeds,
.card svg.icon-moistureNeeds {
  fill: transparent;
  stroke: transparent;
}

svg.icon-dry .dropsSmall,
svg.icon-dry .aquaticLines {
  fill: transparent;
  stroke: transparent;
}

svg.icon-dry .dropMain {
  fill: transparent;
  stroke: $icon-care-color;
}
.card svg.icon-dry .dropMain {
  stroke: $icon-care-card-color;
}

svg.icon-moist .dropMain {
  fill: $icon-care-color;
}

.card svg.icon-moist .dropMain {
  fill: $icon-care-card-color;
}

svg.icon-wet .dropMain,
svg.icon-wet .dropsSmall {
  fill: $icon-care-color;
}

.card svg.icon-wet .dropMain,
.card svg.icon-wet .dropsSmall {
  fill: $icon-care-card-color;
}

svg.icon-aquatic .aquaticLines {
  fill: $icon-care-color;
}

.card svg.icon-aquatic .aquaticLines {
  fill: $icon-care-card-color;
}

svg.icon-aquatic .dropsSmall {
  fill: $icon-care-color;
}

.card svg.icon-aquatic .dropsSmall {
  fill: $icon-care-card-color;
}

.plant-colors svg {
  height: 19px;
  margin-left: 1px;
  margin-right: 1px;
}

svg.icon-white {
  stroke: #000;
  stroke-width: 2;
}

svg.icon-yellow {
  stroke: #000;
}

.color-block {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  display: inline-block;
}

.color-circle {
  border-radius: 50%;
}

.infinite-scroll-component {
  overflow: visible !important
}

.month-line {
  height: 6px;
  background: #ccc;
}

.month-line div:first-child {
  border-left: 0px solid white;
}

.month-line div {
  position: relative;
  border-left: 1px solid white;
}

.monthtip {
  transform: translateX(-50%);
  min-width: 60px;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .65);
  color: #fff;
  font-size: 12px;
  cursor: default;
  text-shadow: none;
  text-align: center;
  overflow: visible;
  padding: 2px 5px;
  transition: opacity .3s ease-in .3s;
  position: absolute;
  /*overflow: hidden;*/
  opacity: 0;
  white-space: nowrap;
  top: 7px; //match month-line height + 1
  z-index: 1;
}

.monthtip:after {
  content: ' ';
  position: absolute;
  top: -5px; //match with border-bottom
  left: 50%;
  display: block;
  height: 0;
  width: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, .65);
  margin-left: 0;
}


div:hover>.monthtip,
div:focus>.monthtip,
div:active>.monthtip {
  visibility: visible;
  opacity: 1
}

div:focus>.monthtip:hover {
  background: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-indent: -9999px;
}

.filters {
  background: $filter-background;
  padding-top: 4px
}

.filters p,
.filters h1,
.filters h2,
.filters h3,
.filters h4,
.filters h5 {
  padding: 6px 10px 0;
  margin: 0;
}

.filters h3 {
  font-size: 1.25rem;
}

.filter-collapse {
  border-bottom: 2px solid white;
  padding: 9px 10px 5px;
}

.filter-collapse .btn {
  font-weight: 700;
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  text-decoration: none;
  padding: 2px 0 0;
  margin-bottom: 4px;
}

.filter-collapse .btn:hover,
.filter-collapse .btn:focus {
  text-decoration: none;
  background-color: transparent;
}

.filter-collapse .btn:after {
  content: ' +';
  line-height: 1;
  position: absolute;
  right: 0px;
  top: 5px;
}

.filter-collapse button.open-true:after {
  content: ' –';
}

// .Collapsible__contentInner {
//   padding: 4px 3px 5px;
// }

.site-title {
  font-weight: 400;
  padding: 20px 0 60px;
  font-size: 2rem;
}

/*.container-main {
  max-width: 1410px;
}
*/

.container-nav {
  border-bottom: 2px solid #707070;
}


/* Plant Detail */

.plant-page h1 {
  line-height: 1.1;
  font-weight: 700;
  margin: 0;
}

.plant-page .careIcons {
  font-size: 12px;
}

.plant-page table {
  background-color: #eeeeee;
  width: 100%;
  margin-bottom: 20px;
}

.plant-page table th {
  max-width: 100px;
}

.plant-page table th,
.plant-page table td {
  padding: 5px calc(var(--bs-gutter-x) * .5);
}

.figure {
  margin-bottom: calc(var(--bs-gutter-x) * .5);
}

.plant-types a {
  text-decoration: none;
  color: $secondary;
}

@include media-breakpoint-down(md) {
  .figure-thumb {
    display: none;
  }

  .images-main {
    // margin-right: calc(var(--bs-gutter-x, 0.75rem) * -1);
    margin-right: -10px;
    margin-bottom: 10px;

    .images-row {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      figure {
        width: auto;
        flex: 0 0 auto;
        margin-right: 10px;
      }

      img {
        height: 50vh;
        // max-width: 90vw;
        // max-height: 35vh;
        object-fit: contain;
      }
    }
  }
}


// .offcanvas.show {
//   visibility: visible;
// }

// @include media-breakpoint-up(md) {

//   .offcanvas-body {
//     // flex-grow: 1;
//     padding: 0;
//     // overflow-y: auto;
//   }

//   .offcanvas-wide.offcanvas-start {
//     // top: 0;
//     // left: 0;
//     // width: 400px;
//     border-right: 0px;
//     transform: none
//   }

//   .offcanvas-wide.offcanvas {
//     position: static;
//     // bottom: 0;
//     // z-index: 1045;
//     // display: flex;
//     // flex-direction: column;
//     // max-width: 100%;
//     visibility: visible;
//     // background-color: #fff;
//     // background-clip: padding-box;
//     // outline: 0;
//     // transition: transform 0.3s ease-in-out;
//   }
// }

.container-offcanvas {
  width: 100%;
}

.plant-header {
  position: relative;
}

@include media-breakpoint-up(md) {

  .plant-header,
  .plant-details {
    float: right;
    width: 50%;
  }

  .plant-images {
    float: left;
    width: 50%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    min-height: 200px;
  }

}

@include media-breakpoint-up(xl) {

  .plant-header,
  .plant-details {
    width: 45%;
  }

  .plant-images {
    width: 55%;
  }

}

@include media-breakpoint-up(md) {

  .masonry-with-columns {
    columns: 2 150px;
    column-gap: 10px;

    figure {
      margin: 0 0px 10px 0;
      display: block;
      width: 100%;
      // text-align: center;

      img {
        width: 100%
      }
    }

    figure:first-of-type {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .figure-thumb {
    display: none;
  }

  // .masonry-with-columns {
  //   figure:first-of-type {
  //     display: none;
  //   }
  // }
}

@media screen and (min-width: 1200px) {
  .figure-thumb {
    display: none;
  }

  .masonry-with-columns {
    figure:first-child {
      display: inline-block;
    }
  }
}


.nav-pages {
  position: fixed;
  bottom: 0;
  background: $filter-background;
  padding: 10px;
  // width: auto;
  // right: 0;

  .pagination {
    margin-bottom: 0;

    .page-link:hover {
      color: #fff;
      background-color: $secondary;
      border-color: #dee2e6;
    }

    .active .page-link:hover {
      z-index: 3;
      color: #fff;
      background-color: #707070;
    }

    .dots {
      padding: 0.375rem 0.75rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .nav-pages {
    .pagination {
      .dots {
        padding: 0.375rem 0.25rem;
      }
    }
  }
}